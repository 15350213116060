// noinspection JSValidateTypes

import React from 'react';
import '../../../assets/scss/client/Footer.scss';

const Footer = () => {
  return(
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <h4>About Us</h4>
              <p style={{color: '#777777', fontSize: '14px', textAlign:'justify'}}>
                We believe that the heritage of the homeland as well as the right and contemporary use of technology is key to the progress of a nation. Keeping this in mind, the individual progresses eventually aggregated in the progress of the whole nation. Sun IT always brings up-to-date, secure as well as verified technology in order to ultimate progress. We have professional software development engineers who are always concerned to deliver the optimum services for clients, it's our passion. At the end of the day, we are working relentlessly for customers to achieve their optimum benefit that is why the clients are always beside us for their services.
              </p>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Home</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>About Us</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Disclaimer</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Privacy Policy</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Terms of Service</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Frequently Asking Question</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Block Chain</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Web Applications</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Cloud Computing</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Internet of Things</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>Artificial Intelligence</a></li>
                <li><i className="bi bi-chevron-right"></i> <a href={() => false}>eBusiness Consultancy</a></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Contact</h4>
              <ul>
                <li><i className="bi bi-phone" style={{fontSize: '15px'}}></i> <a href='tel:8801749608170' style={{paddingLeft: '10px'}}> 01749 608171</a></li>
                <li><i className="bi bi-envelope" style={{fontSize: '12px'}}></i> <a href="mailto:info@sunitltd.net" style={{paddingLeft: '10px'}}>info@sunitltd.net</a></li>
                <li><i className="bi bi-envelope" style={{fontSize: '12px'}}></i> <a href="mailto:offshore@sunitltd.net" style={{paddingLeft: '10px'}}>offshore@sunitltd.net</a></li>
                <li><i className="bi bi-envelope" style={{fontSize: '12px'}}></i> <a href="mailto:hr.admin@sunitltd.net" style={{paddingLeft: '10px'}}>hr.admin@sunitltd.net</a></li>
                <li><i className="bi bi-house" style={{fontSize: '15px'}}></i> <a href={() => false} style={{paddingLeft: '10px'}}>37 Miyapara, Rani Bazar Roard, <br/> Ghoramara, Boalia, Rajshahi-6100</a></li>
                <li>
                  <a href={() => false}>
                    <button className="btn btn-outline-success btn-sm btn-group-lg">Research & Development</button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            &copy; Copyright <strong><span>Sun IT Limited</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Powered by <a href="https://www.sunitltd.net">Sun IT Limited</a>
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href={() => false} className="linkedin"><i className="bi bi-linkedin"></i></a>
          <a href={() => false} className="facebook"><i className="bi bi-facebook"></i></a>
          <a href={() => false} className="twitter"><i className="bi bi-twitter"></i></a>
          <a href={() => false} className="instagram"><i className="bi bi-instagram"></i></a>
        </div>
      </div>
    </footer>
  )
};

export default Footer;