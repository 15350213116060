import React from 'react';
import '../../../assets/scss/client/Navbar.scss';
import logo from '../../../assets/imgs/client/logo.svg';

const Navbar = () => {
  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <nav className="navbar">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="" width="45" height="45" className="d-inline-block align-text-top"/>
              <span>SUN IT</span>
            </a>
          </nav>
        </h1>
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li><a className="nav-link active" href="/">Home</a></li>
            <li><a className="nav-link" href="/about">About</a></li>
            <li><a className="nav-link" href="javascript(0)">Technologies</a></li>
            <li className="dropdown">
              <a href="javascript(0)"><span>Services</span> <i className="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="javascript(0)">eCommerce</a></li>
                <li><a href="javascript(0)">Block Chain</a></li>
                <li><a href="javascript(0)">Cyber Security</a></li>
                <li><a href="javascript(0)">Cloud Computing</a></li>
                <li><a href="javascript(0)">Internet of Things</a></li>
                <li><a href="javascript(0)">Artificial Intelligence</a></li>
                <li><a href="javascript(0)">Computer Networking</a></li>
                <li><a href="javascript(0)">eBusiness Consultancy</a></li>
                <li><a href="javascript(0)">Domain Registration & Hosting</a></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="javascript(0)"><span>Developments</span> <i className="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="javascript(0)">Software</a></li>
                <li><a href="javascript(0)">Mobile Applications</a></li>
                <li><a href="javascript(0)">Desktop Applications</a></li>
                <li><a href="javascript(0)">Web & Web Applications</a></li>
              </ul>
            </li>
            <li><a className="nav-link" href="javascript(0)">Training</a></li>
            <li><a className="nav-link" href="javascript(0)">Contact</a></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <a href="javascript(0)" className="appointment-btn">
          <span className="d-none d-md-inline">For an</span> Appointment</a>
      </div>
    </header>
  );
};
export default Navbar;
