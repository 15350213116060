import { Suspense } from 'react';
import './App.scss';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes } from 'react-router-dom';
import * as Client from '../container/client/Client';
import * as Admin from '../container/admin/Admin';
import MasterLayout from '../hoc/MasterLayout';

const App = () => {
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route path="/" element={<MasterLayout/>}>
              <Route exact index element={<Client.Home />} />
              <Route exact path="/about" element={<Client.About />} />
            </Route>

            <Route path="/" element={<MasterLayout/>}>
              <Route exact index element={<Admin.Home />} />
            </Route>
          </Routes>
        </Suspense>
      </AnimatePresence>
    </>
  );
}

export default App;
