import React from 'react';
import {Outlet} from "react-router-dom";
import Topbar from '../../hoc/client/header/Topbar';
import Navbar from '../../hoc/client/header/Navbar';
import Footer from '../../hoc/client/footer/Footer';

const Client = () => {
  return <>
    <React.Fragment>
      <Topbar/>
      <Navbar/>
      <Outlet />
      <Footer/>
    </React.Fragment>
  </>
};
export default Client;