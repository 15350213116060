import React from 'react';
import '../../../assets/scss/client/Topbar.scss';

const Topbar = () => {
  return <>
    <div id="topbar" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope"></i> <a href="mailto:info@sunitltd.net" id={"infoLink"}>info@sunitltd.net</a>
          <i className="bi bi-phone"></i> <a href='tel:8801749608170' id={'phoneLink'}> +88 01749 60 81 71</a>
        </div>
        <div className="d-none d-lg-flex social-links align-items-center">
          <a href="javascript(0)" className="twitter"><i className="bi bi-twitter"></i></a>
          <a href="javascript(0)" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="javascript(0)" className="instagram"><i className="bi bi-instagram"></i></a>
          <a href="javascript(0)" className="linkedin"><i className="bi bi-linkedin"></i></a>
        </div>
      </div>
    </div>
  </>;
};

export default Topbar;