import React from 'react';
import ClientLayout from './client/ClientLayout';
import AdminLayout from './admin/AdminLayout';

const MasterLayout = () => {
  return <>
    <React.Fragment>
      <ClientLayout/>
      {/*<AdminLayout/>*/}
    </React.Fragment>
  </>
};
export default MasterLayout;